import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import HeroSelectAll from "../../assets/Images/Faq/HeroSelectAll.png";
import RevealAnimation from "../RevealAnimation";
import faqData from "./faqData.json";

function HeroTabSectionFaq() {
  const [activeCategory, setActiveCategory] = useState(faqData[0]?.categoryId);
  const [activeSubCategory, setActiveSubCategory] = useState(
    faqData[0]?.subCategories
      ? faqData[0].subCategories[0]?.subCategoryId
      : null
  );
  const [selectFaq, setSelectFaq] = useState(null);
  const faqRef = useRef(null);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    const selectedCategory = faqData.find(
      (category) => category.categoryId === categoryId
    );
    if (selectedCategory && selectedCategory.subCategories?.length > 0) {
      setActiveSubCategory(selectedCategory.subCategories[0]?.subCategoryId);
    } else {
      setActiveSubCategory(null);
    }
    setSelectFaq(null); // Reset the expanded question when category changes
  };

  const handleSubCategoryClick = (subCategoryId) => {
    setActiveSubCategory(subCategoryId);
    setSelectFaq(null); // Reset the expanded question when subcategory changes
  };

  const toggleFaq = (id) => {
    setSelectFaq(selectFaq === id ? null : id);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (faqRef.current && !faqRef.current.contains(event.target)) {
        setSelectFaq(null); // Close the FAQ if clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [faqRef]);

  const selectedCategory = faqData.find(
    (category) => category.categoryId === activeCategory
  );
  const selectedSubCategory = selectedCategory?.subCategories?.find(
    (subCategory) => subCategory.subCategoryId === activeSubCategory
  );

  return (
    <div>
      <div className="hero-banner-wrap-faq">
        <div className="body-container hero-banner-container">
          <div className="hero-banner-inner-section">
            <div className="banner-content">
              <RevealAnimation component={"h2"} className="title-header">
                FAQ
              </RevealAnimation>
              <RevealAnimation component={"p"} className="sub-title">
                Responses to your most frequent queries
              </RevealAnimation>
            </div>
            <div className="banner-img-wrap-faq">
              <img src={HeroSelectAll} alt="banner" />
            </div>
          </div>
        </div>
      </div>
      <div className="hero-tab-section-faq">
        <div className="body-container hero-tab-list">
          {faqData.map((category, index) => (
            <div
              key={index}
              className={`hero-tab-item ${
                activeCategory === category.categoryId ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category.categoryId)}
            >
              <p>{category.categoryName}</p>
            </div>
          ))}
        </div>
        {selectedCategory?.subCategories && (
          <>
            <div ref={faqRef} className="body-container faq-container">
              <div className="sub-tab-list">
                {selectedCategory.subCategories.map((subCategory, index) => (
                  <div
                    key={index}
                    className={`hero-sub-tab-item ${
                      activeSubCategory === subCategory.subCategoryId
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubCategoryClick(subCategory.subCategoryId)
                    }
                  >
                    <p>{subCategory.subCategoryName}</p>
                  </div>
                ))}
              </div>
              <div className="faq-section-body-items mt-50">
                {selectedSubCategory?.questionsAnswers.map((faq) => (
                  <div key={faq.id} className="faq-section-list">
                    <div
                      onClick={() => toggleFaq(faq.id)}
                      className="faq-section-list-item"
                    >
                      <div>
                        <h2>{faq.question}</h2>
                      </div>
                      <div className="svg-icons-faq">
                        {selectFaq === faq.id ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000000"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m4.5 15.75 7.5-7.5 7.5 7.5"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000000"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    {selectFaq === faq.id && (
                      <div className="faq-answer-item">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default HeroTabSectionFaq;
